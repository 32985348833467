<template>
  <a-modal
    v-model="addSourceVisible"
    :title="(isAdd === 1 ? '添加' : isAdd === 2 ? '修改' : '修改') + '广告源'"
    @onCancel="handleCancel"
    @ok="handleSubmit"
    :width="isAdd === 1 && !specifiecPlat ? 940 : 900"
    dialogClass="source_modal"
  >
    <a-spin :spinning="isLoading">
      <template slot="footer">
        <a-button
          v-if="origin === 'https://app.mediatom.cn'"
          style="margin-right: 500px"
          type="link"
          target="_blank"
          href="https://app.mediatom.cn/docs/#/?blogid=125"
        >如何配置广告源?</a-button
        >
        <a-button @click="handleCancel">取消</a-button>
        <a-button type="primary" @click="handleSubmit">确定</a-button>
      </template>
      <div class="source_wrapper">
        <!-- 左侧广告平台 start -->
        <div class="source_wrapper_plat" v-if="isAdd === 1 && !specifiecPlat">
          <div class="plat_search">
            <a-input v-model.trim="searchKey" placeholder="搜索广告平台"><a-icon slot="suffix" type="search" /></a-input>
          </div>
          <div class="plat_list_wrapper">
            <a-menu :selectedKeys="currentPlat" type="inner" class="menu_plat" @select="handleSelectPlat">
              <a-menu-item
                v-for="item in fliterPlatList"
                :key="item.id"
                :class="item.type === 'notCreat' ? 'notCreat' : 'menu_plat_item'"
              >
                <div class="leftBox" :title="item.name">
                  <span class="icon-logo" v-if="origin === 'https://app.mediatom.cn' && item.type !== 'notCreat'">
                    <img :src="getLogoUrl(item.icon)" />
                  </span>
                  <span style="font-size: 12px; font-weight: 600">{{ item.name }}</span>
                </div>
              </a-menu-item>
            </a-menu>
          </div>
        </div>
        <!-- 左侧广告平台 end -->

        <!-- 右侧内容 start -->
        <div class="source_wrapper_form" :style="{ 'margin-left': isAdd === 1 && !specifiecPlat ? '250px' : '0' }">
          <a-form-model ref="ruleForm" :model="formquery" :rules="rules" v-bind="layout">
            <!-- 新增、编辑广告源 start -->
            <template v-if="isAdd < 3">
              <div v-if="specifiecPlat" class="specifiec-plat-box">
                <a-form-model-item label="账户" class="prop-item">
                  {{ formquery.accountName }}
                </a-form-model-item>
                <a-form-model-item label="广告平台" class="prop-item">
                  {{ formquery.platName }}
                </a-form-model-item>
              </div>
              <!-- 账户 -->
              <a-form-model-item
                v-if="isAdd === 1 && !specifiecPlat"
                label="账户"
                prop="platAccountId"
                :rules="[{ required: true, message: '请选择账户', trigger: 'change' }]"
              >
                <a-select
                  placeholder="请选择账户"
                  showSearch
                  :filter-option="filterOption"
                  v-model="formquery.platAccountId"
                  @change="changePlatAccountId"
                >
                  <a-select-option v-for="item in accountList" :key="item.id" :value="item.id">{{
                    item.name
                  }}</a-select-option>
                </a-select>
                <div v-if="formquery.platId">
                  创建<span
                    style="color: #338aff; cursor: pointer"
                    class="text-a"
                    @click="handleClickAddAcount"
                  >新账户</span
                  >
                </div>
              </a-form-model-item>
              <a-form-model-item label="账户" v-if="isAdd === 2 && !specifiecPlat">
                {{ formquery.accountName }}
              </a-form-model-item>
              <!-- 广告平台名称 -->
              <a-form-model-item label="广告平台" v-if="isAdd === 2 && !specifiecPlat">
                {{ formquery.platName }}
              </a-form-model-item>
              <!-- 广告源名称 -->
              <a-form-model-item
                :rules="[{ required: true, message: '请输入广告源名称', trigger: 'blur' }]"
                label="广告源名称"
                prop="name"
              >
                <a-input placeholder="请输入广告源名称" v-model="formquery.name" />
              </a-form-model-item>
              <!-- 应用ID -->
              <a-form-model-item
                :rules="[{ required: true, message: `请输入${getAppIdLabel(platInfo.id)}`, trigger: 'blur' }]"
                v-if="showAppIds(platInfo.id) || showAppIds(sourceInfo.platId)"
                prop="platAppId"
              >
                <m-tips
                  slot="label"
                  :content="`对应广告平台创建应用后，平台生成的${getAppIdLabel(platInfo.id)}。`"
                  :title="getAppIdLabel(platInfo.id)"
                  :width="200"
                ></m-tips>
                <a-input v-if="allowEdit" :placeholder="`请输入${getAppIdLabel(platInfo.id)}`" v-model="formquery.platAppId" />
                <div v-else>
                  <span>{{ formquery.platAppId }}</span>
                  <a-button type="link" size="small" @click="changeAllowEdit">编辑</a-button>
                </div>
                <a-alert
                  v-if="isAdd === 2 && allowEdit"
                  :message="`修改${getAppIdLabel(platInfo.id)}参数后，所选账号下的所有广告位配置都会同步生效！`"
                  banner
                />
              </a-form-model-item>
              <!-- 广告源ID -->
              <a-form-model-item
                :rules="[{ required: true, message: `请输入${platPlaceLabel(platInfo.id)}`, trigger: 'blur' }]"
                prop="platPlaceId"
              >
                <!-- 其他广告平台label -->
                <span slot="label">{{ platPlaceLabel(platInfo.id) }}</span>
                <m-tips
                  slot="label"
                  :content="`对应广告平台创建应用中创建广告位后，生成的广告位id。`"
                  :width="200"
                ></m-tips>
                <a-input
                  :placeholder="`请输入${platPlaceLabel(platInfo.id)}`"
                  v-model="formquery.platPlaceId"
                  :disabled="platInfo.id === 5"
                />
              </a-form-model-item>
              <!-- 广告样式 -->
              <a-form-model-item
                label="广告样式"
                prop="position"
                :rules="[{ required: true, message: '请选择广告样式', trigger: 'change' }]"
              >
                <a-select
                  placeholder="请选择广告样式"
                  v-model="formquery.position"
                  showSearch
                  :filter-option="filterOption"
                  @change="handleChangePosition"
                >
                  <a-select-option
                    v-for="item in platPositionList"
                    :key="item.id"
                    :value="item.id"
                    showSearch
                    :filter-option="filterOption"
                  >{{ item.name }}</a-select-option
                  >
                </a-select>
              </a-form-model-item>
              <!-- appKey -->
              <a-form-model-item
                v-if="showAppKey(platInfo.id)"
                label="appKey"
                prop="appKey"
                :rules="[{ required: true, message: '请输入appKey', trigger: 'blur' }]"
              >
                <a-input placeholder="请输入appKey" v-model="formquery.appKey" />
              </a-form-model-item>
              <SlotIdForm :query="formquery" :positionId="+formquery.position" :platId="+platInfo.id"></SlotIdForm>
              <!-- 跃盟 -->
              <a-form-model-item
                v-if="+platInfo.id === 1080"
                label="广告位样式ID"
                prop="slotIdList"
                :rules="[{ required: true, message: '请选择广告位样式ID', trigger: 'change' }]"
              >
                <m-select-more
                  style="width: 100%"
                  v-model="formquery.slotIdList"
                  :allData="slotList1080"
                  :searchById="false"
                  :hasIcon="false"
                  :showId="false"
                  label="广告位样式ID"
                  :showSelectedPart="true"
                  :hasSearch="true"
                  width="450px"
                  :canSelectAll="true"
                  :showLabel="false"
                />
              </a-form-model-item>
              <!-- 是否自动创建 -->
              <a-form-model-item v-if="+accountInfo?.isAutoCreate === 1 && +platInfo.id === 1061" prop="isAutoCreate" label="是否自动创建">
                <a-radio-group name="radioGroup" v-model="formquery.isAutoCreate">
                  <a-radio :value="1"> 是 </a-radio>
                  <a-radio :value="0"> 否 </a-radio>
                </a-radio-group>
              </a-form-model-item>
              <template v-if="+platInfo.id === 1061">
                <a-form-model-item label="百青藤模板">
                  <a-button type="link" icon="plus" @click="addTemplate">添加模版配置</a-button>
                </a-form-model-item>
                <div class="template1061" v-for="(radio, index) in formquery.templateRadioList" :key="index">
                  <div class="delete" v-if="index !== 0" @click="handleDeleteItem1061(index)">
                    <a-icon type="close"></a-icon>
                  </div>
                  <a-form-model-item
                    label="Template"
                    :prop="`templateRadioList[${index}].templateSlotId`"
                    :rules="[{ required: true, message: '请选择Template', trigger: 'change' }]"
                  >
                    <a-select placeholder="请选择Template" v-model="radio.templateSlotId">
                      <!-- <a-select placeholder="请选择" v-model="radio."> -->
                      <a-select-option value="SIMG">小图模板，适用于左图右文或右图左文</a-select-option>
                      <a-select-option value="BIMG">大图模板</a-select-option>
                      <a-select-option value="GIMG">组图模板，目前仅支持 3 图</a-select-option>
                      <a-select-option value="VID">视频模板</a-select-option>
                      <a-select-option value="RVID">激励视频模板</a-select-option>
                    </a-select>
                  </a-form-model-item>
                  <a-form-model-item
                    label="AspectRatio"
                    :prop="`templateRadioList[${index}].templateRadioList`"
                    :rules="[{ required: true, message: '请选择AspectRatio', trigger: 'change' }]"
                  >
                    <a-select mode="multiple" placeholder="请选择AspectRatio" v-model="radio.templateRadioList">
                      <a-select-option value="RATIO_2X1">物料比例 2:1</a-select-option>
                      <a-select-option value="RATIO_3X2">物料比例 3:2</a-select-option>
                      <a-select-option value="RATIO_2X3">物料比例 2:3</a-select-option>
                      <a-select-option value="RATIO_16X9">物料比例 16:9</a-select-option>
                      <a-select-option value="RATIO_9X16">物料比例 9:16</a-select-option>
                      <a-select-option value="RATIO_1X1">物料比例 1:1</a-select-option>
                      <a-select-option value="RATIO_4X3">物料比例 4:3</a-select-option>
                      <a-select-option value="RATIO_3X1">物料比例 3:1</a-select-option>
                    </a-select>
                  </a-form-model-item>
                </div>
              </template>
              <!-- 展示类型 软告 1014  展示 -->
              <a-form-model-item
                label="展示类型"
                prop="type"
                v-if="+platInfo.id === 1014"
                :rules="[{ required: true, message: '请输入展示类型', trigger: 'blur' }]"
              >
                <a-select placeholder="请选择展示类型" v-model="formquery.type">
                  <a-select-option v-for="item in typeList" :key="item.id" :value="+item.id">
                    {{ item.name }}
                  </a-select-option>
                </a-select>
              </a-form-model-item>
              <!-- 出价类型 -->
              <a-form-model-item prop="settleType">
                <span slot="label">出价类型</span>
                <a-radio-group name="radioGroup" v-model="formquery.settleType">
                  <a-radio :value="1"> RTB竞价 </a-radio>
                  <a-radio v-if="platInfo.id === 2002" :value="0"> 固价 </a-radio>
                </a-radio-group>
              </a-form-model-item>
              <!-- 应用名称 -->
              <a-form-model-item label="上报应用名称" prop="appnameReplace">
                <a-auto-complete
                  :data-source="appnameReplaceList"
                  :filter-option="filterOption"
                  placeholder="请输入应用名称"
                  v-model="formquery.appnameReplace"
                />
              </a-form-model-item>
              <!-- 应用版本号 -->
              <a-form-model-item label="上报应用版本号" prop="versionReplace">
                <a-auto-complete
                  :data-source="versionReplaceList"
                  :filter-option="filterOption"
                  placeholder="请输入应用版本号"
                  v-model="formquery.versionReplace"
                />
              </a-form-model-item>
              <!-- 应用包名 -->
              <a-form-model-item label="上报应用包名" prop="bundleReplace">
                <a-auto-complete
                  :data-source="bundleReplaceList"
                  :filter-option="filterOption"
                  placeholder="请输入应用包名"
                  v-model="formquery.bundleReplace"
                />
              </a-form-model-item>
              <!-- 广告位宽高 -->
              <a-form-model-item
                prop="width"
                v-if="showHWIds(platInfo.id)"
                label="广告位宽度"
                :rules="[{ required: true, message: '请输入宽度', trigger: 'blur' }]"
              >
                <a-input-number
                  style="width: 100%; border-radius: 5px; height: 36px; line-height: 36px"
                  :min="1"
                  placeholder="请输入宽度"
                  v-model="formquery.width"
                />
              </a-form-model-item>
              <!-- 广告位宽高 -->
              <a-form-model-item
                prop="height"
                label="广告位高度"
                v-if="showHWIds(platInfo.id)"
                :rules="[{ required: true, message: '请输入高度', trigger: 'blur' }]"
              >
                <a-input-number
                  style="width: 100%; border-radius: 5px; height: 36px; line-height: 36px"
                  :min="1"
                  placeholder="请输入高度"
                  v-model="formquery.height"
                />
              </a-form-model-item>
              <!-- 是否校验 -->
              <a-form-model-item v-if="+checkable === 1" label="是否校验" prop="isBlack">
                <a-radio-group name="radioGroup" v-model="formquery.isBlack">
                  <a-radio value="1"> 是 </a-radio>
                  <a-radio value="0"> 否 </a-radio>
                </a-radio-group>
              </a-form-model-item>
              <template>
                <a-form-model-item :wrapperCol="{ span: 24 }">
                  <a-divider>高级配置</a-divider>
                </a-form-model-item>
                <!--
                equipmentRuleType: 0,
                equipmentRuleContent: []
                -->
                <a-form-model-item prop="equipmentRuleContent" label="设备">
                  <div class="flex-box">
                    <div class="flex-left">
                      <a-select placeholder="请选择类型" v-model="formquery.equipmentRuleType">
                        <a-select-option v-for="item in ruleTypeList" :key="item.value" :value="item.value">
                          {{ item.label }}
                        </a-select-option>
                      </a-select>
                    </div>
                    <div class="flex-right">
                      <m-select-more
                        style="width: 100%"
                        class="search_item"
                        v-model="formquery.equipmentRuleContent"
                        :allData="brandList"
                        :searchById="false"
                        :hasIcon="false"
                        :showId="false"
                        label="设备品牌"
                        :showLabel="false"
                      />
                    </div>
                  </div>
                </a-form-model-item>
                <a-form-model-item prop="installRuleContent" label="安装列表">
                  <div class="flex-box">
                    <div class="flex-left">
                      <a-select placeholder="请选择类型" v-model="formquery.installRuleType">
                        <a-select-option :value="0"> 已安装 </a-select-option>
                        <a-select-option :value="1"> 未安装 </a-select-option>
                      </a-select>
                    </div>
                    <div class="flex-right">
                      <m-select-more
                        style="width: 100%"
                        class="search_item"
                        v-model="formquery.installRuleContent"
                        :allData="installAppList"
                        :searchById="false"
                        :hasIcon="false"
                        :showId="false"
                        label="安装列表"
                        :showLabel="false"
                      />
                    </div>
                  </div>
                </a-form-model-item>
              </template>
            </template>
          </a-form-model>
        </div>
        <!-- 右侧内容 end -->
      </div>
    </a-spin>
    <AddAccountModal
      v-bind="$attrs"
      v-if="addAccountVisible"
      :visible="addAccountVisible"
      :platInfo="platInfo"
      :accountInfo="accountInfo"
      :isAddAcount="isAddAcount"
      @modalCancel="addAccountVisible = false"
      :query="query"
      @addNewAccount="getPlatAccounts(2, true)"
      @editAccount="getPlatAccounts(2)"
    />
  </a-modal>
</template>

<script>
import {
  getPlatAccounts,
  gettemplateTypeList,
  updateRtbPlatAppId,
  addRtb,
  updateRtb,
  getPlatPosition,
  replacerInfo,
  getApiPlatformList
} from '@/api/aggregate'
import BiddingSvg from '@/assets/icons/bidding.svg?inline'
import AutoSvg from '@/assets/icons/Auto.svg?inline'
import AddAccountModal from '@/views/aggregate/Config/components/addAccountModal'
import { copy } from '@/utils/string'
import { mapState } from 'vuex'
import { adStyleList, typeList, slotList1080 } from './list'
import { platPlaceLabel, showHWIds, showAppIds, getAppIdLabel, showAppKey } from '@/utils/autoLabel'
import md5 from 'js-md5'
import getList from '@/mixins/getListForManage'
import PricingStrategyForm from '@/components/sourceModal/PricingStrategyForm'
import HostSpotSourceForm from '@/components/sourceModal/HotSpotSourceForm'
import SlotIdForm from '@/components/sourceModal/SlotIdForm'
export default {
  name: 'SourceModal',
  components: { AddAccountModal, BiddingSvg, AutoSvg, PricingStrategyForm, HostSpotSourceForm, SlotIdForm },
  mixins: [getList],
  data () {
    return {
      layout: {
        labelCol: { span: 7 },
        wrapperCol: { span: 16 }
      },
      // 账户绑定参数
      // 广告源参数
      formquery: {
        appId: '',
        platId: '',
        platAccountId: undefined,
        platAppId: '',
        name: '',
        platPlaceId: undefined,
        templateIds: [],
        settleType: 1,
        isBlack: '0',
        floatingRate: 0,
        templateTypeId: undefined,
        appnameReplace: undefined,
        versionReplace: undefined,
        bundleReplace: undefined,
        equipmentRuleType: 0,
        equipmentRuleContent: [],
        installRuleType: 0,
        installRuleContent: [],
        adScene: undefined,
        slotIdList: [],
        isAutoCreate: 0,
        templateRadioList: [
          {
            templateRadioList: [],
            templateSlotId: undefined
          }
        ]
      },
      adStyleList,
      typeList,
      slotList1080,
      rules: {},
      rule: {},
      // 广告平台列表
      platList: [],
      // 当前广告平台（绑定左侧menu）
      currentPlat: [],
      // 左侧menu搜索字段
      searchKey: '',
      // 当前选择的广告平台
      platInfo: {
        id: '',
        accessType: ''
      },
      platPositionList: [],
      // 账户列表
      accountList: [],
      // 添加账户弹窗
      addAccountVisible: false,
      isAddAcount: true,
      hide: true,
      hasGetTemplateList: false,
      // 模板类型列表
      templateTypeList: [],
      // 应用ID是否正在修改
      allowEdit: false,
      // 修改之前的应用ID
      editPlatAppId: '',
      isLoading: false,
      ruleTypeList: [
        {
          installLabel: '已安装',
          label: '包括',
          value: 0
        },
        {
          installLabel: '未安装',
          label: '不包括',
          value: 1
        }
        // {
        //   label: '大于',
        //   value: 2
        // },
        // {
        //   label: '小于',
        //   value: 3
        // }
      ],
      appnameReplaceList: [],
      versionReplaceList: [],
      bundleReplaceList: []
    }
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    isAdd: {
      // 1: 新增；2：编辑；3：配置,4: 批量配置
      type: Number,
      default: 0
    },
    sourceInfo: {
      default: () => ({}),
      type: Object
    },
    query: {
      default: () => ({}),
      type: Object
    },
    groupName: {
      default: '',
      type: String
    },
    testList: {
      default: () => [],
      type: Array
    },
    hideInfo: {
      default: () => [],
      type: Array
    },
    brandList: {
      default: () => [],
      type: Array
    },
    installAppList: {
      default: () => [],
      type: Array
    },
    specifiecPlat: {
      type: Boolean,
      default: false
    },
    specifiecData: {
      default: () => ({}),
      type: Object
    }
  },
  watch: {
    sourceInfo: {
      handler (val) {
        // 编辑状态下初始化广告源参数
        if (this.isAdd === 1) {
          this.allowEdit = true
          return
        } else {
          this.formquery = JSON.parse(JSON.stringify(val))
          this.initRule()
        }
        // UI组件绑定String格式
        this.formquery.templateIds && (this.formquery.templateIds = this.formquery.templateIds.map((item) => '' + item))
      },
      deep: true,
      immediate: true
    },
    // 应用id编辑不允许输入汉字和空格
    'formquery.platAppId': {
      handler (newValue, oldValue) {
        if (!newValue) {
          return
        }
        this.formquery.platAppId = newValue.replace(/[\u4e00-\u9fa5/\s+/]/g, '')
      }
    },
    isAdd: {
      handler (val) {
        if (val === 4) {
          let hideInfoPosition = this.hideInfo.map((x) => x.position)
          hideInfoPosition = [...new Set(hideInfoPosition)]
          if (!this.hideInfo.some((it) => it.accessType === 0)) {
            this.hide = false
          } else if (
            this.hideInfo.some((it) => it.accessType === 0) &&
            this.hideInfo.length >= 2 &&
            // 去重后样式的长度大于1表示样式不唯一
            hideInfoPosition.length > 1
          ) {
            this.hide = false
          }
          !this.hide && this.initDefault()
        }
      },
      deep: true,
      immediate: true
    }
  },
  computed: {
    ...mapState({
      // 角色
      role: (state) => state.user.roles[0],
      // 平台名
      title: (state) => state.autoweb.title,
      allRoutes: (state) => state.permission.allRoutes,
      origin: (state) => state.autoweb.origin,
      checkable: (state) => state.user.checkable,
      sensitivity: (state) => state.asyncPermission.sensitivity,
      currentLoginId: (state) => state.user.id
    }),
    showTemplateIds () {
      const id = this.platInfo.id
      // 需要显示素材模板的广告平台
      const showPlatList = [
        997, 987, 989, 995, 998, 1000, 1007, 1008, 1011, 1014, 1015, 1020, 1025, 1026, 1027, 1028, 1031, 1032, 1034,
        1035, 1037, 1041, 1043, 1047
      ]
      return showPlatList.includes(id)
    },
    showAdType () {
      const show = +this.formquery.position === 10
      return show
    },
    accountInfo () {
      const account = this.accountList.find((item) => item.id === this.formquery.platAccountId)
      return account || null
    },
    addSourceVisible: {
      get () {
        return this.visible
      },
      set (val) {
        this.$emit('modalCancel')
      }
    },
    // 搜索、显示的平台列表
    fliterPlatList () {
      return this.platList.filter((item) => item.name.toLowerCase().includes(this.searchKey.toLowerCase()))
    },
    showDrawBtnPos () {
      /**
       * 展示条件：
       * 插屏 && 广告平台为API
       * */
      const show = +this.formquery.position === 3
      return show
    }
  },
  async mounted () {
    await this.platformLinkList()
    if (this.isAdd === 2) {
      this.platInfo = this.platList.find((item) => item.id === this.formquery.platId)
      this.getPlatAccounts(2)
    } else if (this.isAdd === 1 && this.specifiecPlat) {
      this.platInfo = this.platList.find((item) => +item.id === +this.specifiecData.platId)
      this.formquery.platId = this.platInfo.id
      await this.getPlatAccounts(2)
      this.formquery.platAccountId = this.query.accountId
      this.formquery.accountName = this.specifiecData.name
      this.formquery.platName = this.specifiecData.platName
    }
    this.formquery.appId = this.query.appId
    this.dealPlatPlaceAndPlatApp()
    this.gettemplateTypeList()
    this.getPlatPosition()
  },
  methods: {
    showAppIds,
    getAppIdLabel,
    showAppKey,
    handleDeleteItem1061 (index) {
      this.formquery.templateRadioList.splice(index, 1)
      this.$message.success('删除成功！')
    },
    addTemplate () {
      if (!this.formquery.templateRadioList) {
        this.formquery.templateRadioList = []
      }
      this.formquery.templateRadioList.push({
        templateRadioList: [],
        templateSlotId: undefined
      })
      this.$message.success('添加成功')
    },
    async getReplacerInfo () {
      const { data = {} } = await replacerInfo({
        platAccountId: this.formquery.platAccountId
      })
      const { bundleReplaceList = [], versionReplaceList = [], appnameReplaceList = [] } = data
      this.bundleReplaceList = bundleReplaceList
      this.versionReplaceList = versionReplaceList
      this.appnameReplaceList = appnameReplaceList
    },
    handleChangePosition (e) {
      const positionName = this.platPositionList.find((item) => +item.id === +e).name
      if (positionName.includes('开屏') || positionName.includes('开机')) {
        // 开屏
        this.$set(this.formquery, 'width', 1080)
        this.$set(this.formquery, 'height', 1920)
      } else if (positionName.includes('插屏')) {
        // 插屏
        this.$set(this.formquery, 'width', 1080)
        this.$set(this.formquery, 'height', 1920)
      } else if (positionName.includes('激励视频')) {
        this.$set(this.formquery, 'width', 720)
        this.$set(this.formquery, 'height', 1280)
      } else if (positionName.includes('banner') || positionName.includes('横幅')) {
        this.$set(this.formquery, 'width', 720)
        this.$set(this.formquery, 'height', 100)
      } else {
        this.$set(this.formquery, 'width', 1280)
        this.$set(this.formquery, 'height', 720)
      }
    },
    getRuleQuery () {
      this.formquery.segmentRule = []
      // 设备
      if (this.formquery.equipmentRuleContent.length) {
        this.formquery.segmentRule.push({
          ruleId: 7,
          ruleContent: this.formquery.equipmentRuleContent,
          ruleType: this.formquery.equipmentRuleType
        })
      }
      // 安装列表
      if (this.formquery.installRuleContent.length) {
        this.formquery.segmentRule.push({
          ruleId: 15,
          ruleContent: this.formquery.installRuleContent,
          ruleType: this.formquery.installRuleType
        })
      }
    },
    initRule () {
      (this.sourceInfo.segmentRule || []).forEach((item) => {
        // 设备
        if (+item.ruleId === 7) {
          this.$set(this.formquery, 'equipmentRuleContent', item.ruleContent)
          this.$set(this.formquery, 'equipmentRuleType', +item.ruleType || 0)
        }
        // 安装列表
        if (+item.ruleId === 15) {
          this.$set(this.formquery, 'installRuleContent', item.ruleContent)
          this.$set(this.formquery, 'installRuleType', +item.ruleType || 0)
        }
      })
    },
    initDefault () {
      this.hideInfo.forEach((item, index) => {
        if (item.position === '1') {
          // 开屏 广告交互 开启
          item.hotspot = 'A'
          // 广告交互样式 摇一摇
          item.hotspotType = 2
          // 灵敏度  50
          item.sensitivity = 50
        } else if (item.position === '2') {
        } else if (item.position === '3') {
          // 广告交互        关闭
          item.hotspot = 'S'
        } else if (item.position === '4') {
          // 原生自渲染
        } else if (item.position === '5') {
          // 激励视频
        } else if (item.position === '6') {
          // 原生模板
          // 模板类型      图文混合
          item.templateTypeId = 0
          // 左右边距           0
          item.lrMargin = 0
          // 上下边距           0
          item.tbMargin = 0
        } else if (item.position === '7') {
          // 全屏视频
        } else if (item.position === '8') {
          // Draw视频
        } else if (item.position === '10') {
          // 原生混合
          // 渲染方式       自渲染
          item.adStyle = 2
        }
      })
    },
    // 切换竞价底价策略 清空校验
    changeSet (data) {
      this.$refs.ruleForms.clearValidate()
    },
    handleClickAddAcount () {
      this.isAddAcount = true
      this.addAccountVisible = true
    },
    filterOption (input, option) {
      return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
    },
    // 处理应用图标
    getLogoUrl (url) {
      if (url) {
        return url
      } else {
        return 'https://creative.medproad.com/medpro/imgs/logo.png'
      }
    },
    platPlaceLabel,
    showHWIds,
    // 应用ID编辑
    changeAllowEdit () {
      this.allowEdit = true
      // 保存原始应用ID
      this.editPlatAppId = this.formquery.platAppId
    },
    // 获取模版类型列表
    async gettemplateTypeList () {
      const resp = await gettemplateTypeList()
      this.templateTypeList = resp.data || []
    },
    // 复制方法的调用和反馈
    copyId (id) {
      const result = copy(id)
      if (result === false) {
        this.$message.error('不支持复制')
      } else {
        this.$message.success('复制成功')
      }
    },
    // 获取账户列表
    /**
     * type 默认为1，即需要账户id选中第一个，传入其它值则不会默认选择第一项（编辑模式）
     * @param {*} type
     */
    async getPlatAccounts (type = 1, addsuccess = false) {
      const resp = await getPlatAccounts({
        platId: this.formquery.platId,
        appId: this.query.appId
      })
      this.accountList = resp.data || []
      if (type === 1 && !this.specifiecPlat) {
        this.accountList.length > 0 && (this.formquery.platAccountId = this.accountList[0].id)
      } else if (addsuccess) {
        this.formquery.platAccountId = this.accountList[this.accountList.length - 1].id
      }
      // 获取完账号后，获取该账号下对应的上传包信息列表
      this.getReplacerInfo()
    },
    // 广告主样式
    async getPlatPosition () {
      const { data = [] } = await getPlatPosition({
        platId: this.formquery.platId
      })
      // data.forEach((item) => {
      //   item.id = +item.id
      // })
      this.platPositionList = data
    },
    // 选中广告平台
    handleSelectPlat ({ key }) {
      if (key === 'notCreat') return
      this.$refs.ruleForm.clearValidate()
      // 当前广告平台ID
      this.currentPlat = [key]
      this.formquery.platId = key
      this.formquery.platPlaceId = undefined
      this.formquery.platAccountId = undefined
      this.formquery.slotId = undefined
      this.formquery.slotIdList = []
      this.formquery.settleType = 1
      this.getPlatPosition()
      // 保存当前广告平台信息
      this.platInfo = this.platList.find((item) => item.id === key)
      // 获取当前广告平台的账号
      this.getPlatAccounts().then(() => {
        this.changePlatAccountId(this.formquery.platAccountId)
      })
      // 仓渊ADX、自定义广告平台的广告源ID 应用ID随机生成
      if (key === 5) {
        this.formquery.platPlaceId = md5('' + new Date().getTime() + Math.random()).slice(0, 10)
        this.formquery.platAppId = md5('' + new Date().getTime() + Math.random()).slice(0, 10)
      } else {
        this.formquery.platPlaceId = undefined
        this.formquery.platAppId = undefined
      }
      // 爱奇艺、拼多多、 同程、瑞狮自动生成应用ID
      if (key === 1001 || key === 1027 || key === 1024 || key === 1031) {
        this.formquery.platAppId = md5('' + new Date().getTime() + Math.random()).slice(0, 10)
      }
    },
    // 获取广告平台列表
    async platformLinkList () {
      this.isLoading = true
      const resp = await getApiPlatformList()
      const arr = resp.data || []
      const already = arr.filter((item) => +item.isUsed === 1)
      const haveNot = arr.filter((item) => +item.isUsed !== 1)
      const obj = {
        name: '未创建广告源的广告平台',
        type: 'notCreat',
        id: 'notCreat'
      }
      this.platList = [...already, ...[obj], ...haveNot] || []
      // 添加模式选中第一项并获取当前广告平台下的账户
      if (this.isAdd === 1 && !this.specifiecPlat) {
        if (already.length === 0) {
          // 选中未使用的广告平台
          this.platInfo = this.platList[1]
          this.currentPlat[1] = this.platInfo.id
        } else {
          // 使用第一个广告平台
          this.platInfo = this.platList[0]
          this.currentPlat[0] = this.platInfo.id
        }
        this.formquery.platId = this.platInfo?.id
      }
      this.isLoading = false
    },
    dealPlatPlaceAndPlatApp () {
      if (this.isAdd !== 1) {
        return
      }
      // 仓渊ADX、自定义广告平台的广告源ID 应用ID随机生成
      if (+this.platInfo.id === 5 || +this.platInfo.accessType === 2) {
        this.formquery.platPlaceId = md5('' + new Date().getTime() + Math.random()).slice(0, 10)
        this.formquery.platAppId = md5('' + new Date().getTime() + Math.random()).slice(0, 10)
      } else {
        this.formquery.platPlaceId = undefined
        this.formquery.platAppId = undefined
      }
      // 爱奇艺、拼多多、同程、瑞狮自动生成应用ID
      if (
        +this.platInfo.id === 1001 ||
        +this.platInfo.id === 1027 ||
        +this.platInfo.id === 1024 ||
        +this.platInfo.id === 1031
      ) {
        this.formquery.platAppId = md5('' + new Date().getTime() + Math.random()).slice(0, 10)
      }
      this.getPlatAccounts().then(() => {
        this.changePlatAccountId(this.formquery.platAccountId)
      })
    },
    handleCancel () {
      this.addSourceVisible = false
    },
    // 确认按钮点击事件
    async handleSubmit () {
      if (this.isAdd < 3) {
        this.$refs.ruleForm.validate((validate) => {
          if (!validate) return false
          this.handleAddOrEdit()
        })
      } else {
        this.$refs.ruleForms.validate((validate) => {
          if (!validate) return false
          this.handleAddOrEdit()
        })
      }
    },
    // 修改账户事件
    changePlatAccountId (e) {
      const account = this.accountList.find((item) => item.id === e)
      // 仓渊ADX、拼多多、同程、爱奇艺、瑞狮修改账户时，由于有默认生成的应用ID，应用ID不需要变化
      if (account && account.platAppId) {
        this.formquery.platAppId = account.platAppId
        this.allowEdit = false
      } else if (
        this.platInfo.id !== 5 &&
        this.platInfo.id !== 1001 &&
        this.platInfo.id !== 1027 &&
        this.platInfo.id !== 1024 &&
        this.platInfo.id !== 1031 &&
        +this.platInfo.accessType !== 2
      ) {
        this.formquery.platAppId = undefined
        this.allowEdit = true
      }
      this.getReplacerInfo()
    },
    // 添加或编辑广告源
    async handleAddOrEdit () {
      let editRes
      // 提交前先判断是否修改了应用ID
      if (this.editPlatAppId && this.editPlatAppId !== this.formquery.platAppId) {
        // 若修改了应用ID则调用修改应用ID接口
        editRes = await updateRtbPlatAppId({
          id: this.formquery.id,
          platId: this.formquery.id,
          platAccountId: this.formquery.platAccountId,
          platAppId: this.formquery.platAppId
        })
      }
      // 添加
      if (this.isAdd === 1) {
        this.getRuleQuery()
        const resp = await addRtb(this.formquery)
        if (resp.code === 200) {
          this.$message.success('添加成功!')
          this.$emit('changeSource')
          this.addSourceVisible = false
        } else if (resp.code === 10002) {
          this.$confirm({
            title: '提示',
            content: resp.msg,
            onOk () {},
            cancelText: '取消',
            onCancel () {}
          })
        }
      } else if (this.isAdd === 2) {
        this.getRuleQuery()
        // 编辑 若修改应用ID成功，则继续完成编辑操作
        if ((editRes && editRes.code === 200) || !editRes) {
          const resp = await updateRtb(this.formquery)
          if (resp.code === 200) {
            this.$message.success('修改成功!')
            this.$emit('changeSource')
            this.addSourceVisible = false
          }
        }
      }
    }
  }
}
</script>
<style lang="less">
.source_modal .ant-modal-body {
  padding: 10px 0 10px 10px;
  position: relative;
  background: #eee;
}
</style>

<style lang="less" scoped>
.source_wrapper {
  top: 0;
  left: 0;
  height: 60vh;
  overflow-y: hidden;
  overflow-x: hidden;
  .source_wrapper_plat {
    border-radius: 5px;
    background-color: #fff;
    width: 240px;
    position: absolute;
    height: calc(100% - 0px);
    overflow: hidden;
    display: flex;
    flex-direction: column;
    .plat_search {
      border-bottom: 1px solid #ccc;
      padding: 20px;
    }
    .plat_list_wrapper {
      flex-grow: 1;
      height: 0;
      overflow-y: auto;
      &::-webkit-scrollbar {
        width: 7px;
      }
      &::-webkit-scrollbar-thumb {
        border-radius: 5px;
        box-shadow: inset 0 0 5px fade(@primary-color, 3%);
        background: fade(@primary-color, 20%);
      }
      &::-webkit-scrollbar-track {
        box-shadow: inset 0 0 5px fade(@primary-color, 3%);
        border-radius: 0;
        background: rgba(0, 0, 0, 0.1);
      }
      .menu_plat {
        .menu_plat_item {
          margin: 0;
          border-bottom: 1px solid #ccc;
          border-left: 3px solid #fff;
          display: flex;
          justify-content: center;
          position: relative;
          div {
            display: flex;
            justify-content: flex-start;
          }
          &.ant-menu-item-selected {
            border-left: 3px solid;
          }
        }
        .notCreat {
          height: 50px;
          margin: 0;
          border-top: 12px solid #ebebeb;
          font-weight: 500;
          cursor: default;
          border-bottom: 1px solid #ccc;
          &:hover {
            color: inherit;
          }
        }
      }
    }
  }
  .source_wrapper_form {
    padding: 20px;
    border-radius: 5px;
    background: #fff;
    margin-right: 10px;
    height: 100%;
    overflow-y: auto;
    padding-top: 10px;
    border-left: 10px solid #fff;
    border-top: 10px solid #fff;
    border-bottom: 10px solid #fff;
    &::-webkit-scrollbar {
      width: 7px;
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 5px;
      box-shadow: inset 0 0 5px fade(@primary-color, 3%);
      background: fade(@primary-color, 20%);
    }
    &::-webkit-scrollbar-track {
      box-shadow: inset 0 0 5px fade(@primary-color, 3%);
      border-radius: 0;
      background: rgba(0, 0, 0, 0.1);
    }
    ::v-deep {
      .el-radio.is-bordered {
        margin-right: 0px;
      }
      .el-radio.is-bordered.is-checked {
        background: fade(@primary-color, 10%);
        border-radius: 5px;
        border: 1px solid fade(@primary-color,  30%);
      }
      .el-radio__input.is-checked + .el-radio__label {
        color: @primary-color;
      }
      .el-radio__input {
        display: none;
      }
    }
    .inputNumber {
      border-radius: 5px;
      height: 36px;
      line-height: 36px;
    }
  }
}
.leftBox {
  width: 180px;
  height: 100%;
  .icon-logo {
    height: 100%;
    aspect-ratio: 1/1;
    border-radius: 5px;
    overflow: hidden;
    min-width: 42px;
    margin-right: -14px;
    img {
      height: 20px;
      width: 20px;
      border-radius: 10px;
    }
  }
}
.ant-popover-inner-content {
  padding: 1px 16px;
  color: rgba(0, 0, 0, 0.65);
}
.flex-box {
  display: flex;
  flex-wrap: nowrap;
  position: relative;
  .flex-left {
    width: 30%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .flex-right {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
.specifiec-plat-box {
  display: flex;
  padding-left: 160px;
  .prop-item {
    width: 300px;
    display: flex;
    flex-wrap: nowrap;
  }
}
.template1061{
  position: relative;
  border: 1px solid #eee;
  border-radius: 10px;
  padding: 10px 10px 0 0;
  .delete{
    position: absolute;
    color: #ccc;
    top: 0;
    right: 2px;
    cursor: pointer;
  }
}
</style>
