<template>
  <div class="api-source-form-container">
    <a-form-model-item v-if="isYunqing" prop="priorityRace">
      <m-tips
        slot="label"
        content="开启优先竞胜，广告填充后不参与竞价，优先展示广告。结算价格以该广告位配置的其他预算中填充的最高价格为基准，按相应方式加价计费。Mediatom SDK 版本2.7.4及以上支持该功能"
        :title="'优先竞胜'"
        :width="250"
      ></m-tips>
      <a-switch size="small" :checked="formquery.priorityRace === 'A'" @change="changeSts" />
    </a-form-model-item>
    <a-form-model-item v-if="isYunqing && formquery.priorityRace === 'A'" prop="floatingType">
      <m-tips
        slot="label"
        content="百分比说明：按照该广告位配置的其他预算中填充的最高价格进行百分比上浮后结算。固定值：按照该广告位配置的其他预算中填充的最高价格增加固定值后结算。"
        :title="'浮动方式'"
        :width="250"
      ></m-tips>
      <el-radio-group v-model="formquery.floatingType" size="medium">
        <el-radio :label="1" border>百分比</el-radio>
        <el-radio :label="2" border>固定值</el-radio>
      </el-radio-group>
    </a-form-model-item>
    <a-form-model-item v-if="isYunqing && formquery.priorityRace === 'A'" :label="priorityRaceLabel" prop="floatingValue">
      <div class="number-input">
        <a-input-number
          style="width: 200px"
          class="input"
          :placeholder="'请输入' + priorityRaceLabel"
          v-model.trim="formquery.floatingValue"
          :min="0"
        />
        <div v-if="formquery.floatingType === 1" class="suffix">{{ moneyType }}</div>
      </div>
    </a-form-model-item>
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  name: 'ApiSourceForm',
  data () {
    return {
      formqueryTemp: {
        priorityRace: 'S',
        floatingType: 1,
        floatingValue: undefined
      }
    }
  },
  created () {
    this.$set(this.formqueryTemp, 'priorityRace', this.query.priorityRace || 'A')
    this.$set(this.formqueryTemp, 'floatingType', this.query.floatingType || 1)
    this.$set(this.formqueryTemp, 'floatingValue', this.query.floatingValue)
  },
  computed: {
    formquery: {
      get () {
        return this.query
      },
      set (val) {
        const formquery = {
          ...this.formqueryTemp,
          ...val
        }
        this.$emit('changeQuery', formquery)
      }
    },
    ...mapState({
      // 角色
      role: (state) => state.user.roles[0],
      adminUserId: (state) => state.user.adminUserId,
      moneyType: (state) => +state.user.cur === 1 ? '$' : '¥',
      currentLoginId: (state) => state.user.id
    }),
    isYunqing () {
      return +this.currentLoginId === 25
    },
    priorityRaceLabel () {
      return this.formquery.floatingType === 1 ? '浮动比例' : '浮动值'
    }
  },
  props: {
    query: {
      default: () => ({
        priorityRace: 'S',
        floatingType: 1,
        floatingValue: undefined
      }),
      type: Object
    },
    positionId: {
      default: 0,
      type: [Number, String]
    }
  },
  methods: {
    changeSts (e) {
      this.formquery.priorityRace = e ? 'A' : 'S'
    }
  }
}
</script>

<style lang="less" scoped>
.number-input {
  display: flex;
  align-items: center;
  .input {
    width: 100px;
  }
  .suffix {
    display: inline-block;
    border-top: 1px solid #dcdfe6;
    border-right: 1px solid #dcdfe6;
    border-bottom: 1px solid #dcdfe6;
    border-left: 0px;
    border-radius: 0 3px 3px 0;
    background-color: #fafafa;
    text-align: center;
    height: 32px;
    line-height: 32px;
    width: 40px;
  }
}
</style>
